import { Col, Grid, Row } from 'react-flexbox-grid'
import React from 'react'
import styled from 'styled-components'
import { StUl } from '../components/elements/list'
import { StBold } from '../components/elements/bold'
import { StTitle } from '../components/elements/headline'

const grid = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
}
const StDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const CONTENT_TEXTBLOCK_TOPICS = {
  content: (
    <StDiv>
      <StTitle>Mögliche Themen der SpeaKonf</StTitle>
      <Grid fluid>
        <Row>
          <Col {...grid}>
            <StUl>
              <li>
                Schreiben von <StBold>Abstracts</StBold> und{' '}
                <StBold>Kurz-Bios</StBold>
              </li>
              <li>
                <StBold>Erfahrungsaustausch</StBold> zu verschiedenen
                Konferenzen
              </li>
              <li>
                <StBold>roter Faden</StBold> für einen Vortrag
              </li>
              <li>
                <StBold>inhaltliche Erstellung</StBold> der Folien
              </li>
              <li>
                <StBold>Tools</StBold> für die Folienerstellung
              </li>
              <li>
                Erstellung von <StBold>Grafiken</StBold> und{' '}
                <StBold>Designtipps</StBold>
              </li>
              <li>
                Verwendung von <StBold>Bildern</StBold> inklusive{' '}
                <StBold>Urheberrechtsfragen</StBold>
              </li>
            </StUl>
          </Col>
          <Col {...grid}>
            <StUl>
              <li>
                “Absolut <StBold>souverän</StBold> wirken bei totaler
                Ahnungslosigkeit”
              </li>
              <li>
                Best Practices für <StBold>Live-Demos</StBold>
              </li>
              <li>
                <StBold>Feedback</StBold> von Zuhörern
              </li>
              <li>
                <StBold>Sprechübungen</StBold> (Betonung, Tempo)
              </li>
              <li>
                <StBold>Probevorträge</StBold> vor laufender Kamera mit Feedback
              </li>
            </StUl>
          </Col>
        </Row>
      </Grid>
    </StDiv>
  ),
}

import React from 'react'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-flexbox-grid'

// Component imports
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import { InfoBlock } from '../components/infoblock'
import { TextBlock } from '../components/textblock'

// Content imports
import { CONTENT_INFOBLOCK_UNKONF_HEROIMAGE } from '../content/infoblock_heroimage'
import { CONTENT_INFOBLOCK_COUNTDOWN_BOX } from '../content/infoblock_countdown'
import { CONTENT_TEXTBLOCK_WHY_A_SPEAKONF } from '../content/textblock_why_a_speakonf'
import { CONTENT_INFOBLOCK_WHAT_IS_A_UNKONF } from '../content/infoblock_what_is_a_unkonf'
import { CONTENT_TEXTBLOCK_TOPICS } from '../content/textblock_topics'
import { CONTENT_INFOBLOCK_UNKONF_WHO } from '../content/infoblock_who_can_participate'
import { CONTENT_INFOBLOCK_UNKONF_WHERE } from '../content/infoblock_when_where'
import { CONTENT_INFOBLOCK_UNKONF_WHEN } from '../content/infoblock_when_where'
import { CONTENT_INFOBLOCK_SPONSOR } from '../content/infoblock_our_sponsors'
import { CONTENT_INFOBLOCK_APPRECIATION } from '../content/infoblock_visit_appreciation'

const StGrid = styled(Grid)`
  padding: 0 8px;
`

const StCol = styled(Col)`
  padding: 0;
`

const WHEN_WHERE_GRID = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
}

const SEO_KEYWORDS = [
  `jug`,
  `jug-da`,
  `speakonf`,
  `unkonf`,
  `darmstadt`,
  `conference`,
  `konferenz`,
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={SEO_KEYWORDS} />

    <InfoBlock {...CONTENT_INFOBLOCK_UNKONF_HEROIMAGE} />
    <InfoBlock {...CONTENT_INFOBLOCK_COUNTDOWN_BOX} />
    <TextBlock {...CONTENT_TEXTBLOCK_WHY_A_SPEAKONF} />
    <InfoBlock {...CONTENT_INFOBLOCK_WHAT_IS_A_UNKONF} />

    <TextBlock {...CONTENT_TEXTBLOCK_TOPICS} />
    <InfoBlock {...CONTENT_INFOBLOCK_UNKONF_WHO} />

    <StGrid fluid>
      <Row>
        <StCol {...WHEN_WHERE_GRID}>
          <InfoBlock
            {...CONTENT_INFOBLOCK_UNKONF_WHEN}
            justifyContent={'space-between'}
          />
        </StCol>
        <StCol {...WHEN_WHERE_GRID}>
          <InfoBlock
            {...CONTENT_INFOBLOCK_UNKONF_WHERE}
            justifyContent={'space-between'}
          />
        </StCol>
      </Row>
    </StGrid>
    <InfoBlock {...CONTENT_INFOBLOCK_SPONSOR} />
    <InfoBlock {...CONTENT_INFOBLOCK_APPRECIATION} />
  </Layout>
)

export default IndexPage

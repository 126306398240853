import { INFO_THEME_COUNTDOWN } from '../components/infoblock'
import React from 'react'
import CountDown from '../components/countdown'

export const CONTENT_INFOBLOCK_COUNTDOWN_BOX = {
  content: <CountDown />,
  action: null,
  title: null,
  theme: INFO_THEME_COUNTDOWN,
  disableMargin: true,
}

import React from 'react'
import styled from 'styled-components'
import PropType from 'prop-types'

const StContainer = styled.div`
  padding: 6rem 2rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
`

export const TextBlock = props => {
  return <StContainer>{props.content}</StContainer>
}

TextBlock.propTypes = {
  content: PropType.node.isRequired,
}
